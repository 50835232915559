import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShapeBlog from "../components/shapes/ShapeBlog"

const ServicesPage = () => {
  return (
    <Layout>
      <SEO title="Usługi" />
      <main className="services_page">
        <ShapeBlog />

        <section className="page_header_wrapper">
          <h1 className="page_header" data-text="&#60;Usługi/&#62;">
            &#60;Usługi/&#62;
          </h1>
        </section>
        <section className="container-fluid services_section_description">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <h4>Aktualizacja treści i zarządzanie stroną</h4>
              <p>
                Dodajemy, usuwamy i zmieniamy dla Ciebie treści na Twojej
                stronie. Zarządzatmy Twoją stroną tak żeby wszystkie pojawiające
                się na stronie treści były aktualne.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>Zmiana zdjęć i grafik na nowe</h4>
              <p>
                Zmienimy dla Ciebie zdjęcia oraz grafikę, które chciałbyś usunąć
                lub podmienić na nowe.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>Dodawanie wpisów blogowych</h4>
              <p>
                Dodajemy/edytujemy dla Ciebie wpisy w aktualnościach oraz na
                blogu o przesłane przez Ciebie treści (opcjonalnie możemy za
                dodatkową opłatą zlecić stworzenie dla Ciebie treści przez
                copywritera)
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>Dodawanie i usuwanie produktów</h4>
              <p>
                Dodajemy lub usuwamy produkty z Twojej strony internetowej lub
                sklepu.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>Zmiana danych elementów</h4>
              <p>
                Na życzenie zmieniamy poszczególne elementy strony, które
                chciałbyś edytować.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>Edycja tekstów</h4>
              <p>
                Możesz zlecić nam w każdej chwali dowolną edycję tekstów, które
                wyświetlane są na Twojej stronie.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid services_section_secure">
          <h3>Zabezpieczenie</h3>
          <p className="desc">
            Zabezpieczamy Twoją stronę tworząc regularne kopie zapasowe, tak aby
            nawet w razie awarii lub ataku Twój biznes był w pełni bezpieczny i
            abyśmy mogli przywrócić jego działanie w krótkim czasie, tak żebyś
            nie poniósł żadnych szkód.
          </p>
          <div className="row">
            <div className="col-lg-3 col-sm-6 services_section_secure__item">
              <img
                src={require("../assets/images/ico_services_secure_1.svg")}
                alt=""
              />
              <h4>Regularna aktualizacja silnika CMS, motywów i wtyczek</h4>
              <p>
                Regularnie aktualizujemy wszystkie komponenty Twojej strony do
                najnowszych dostępnych na rynku wersji.
              </p>
            </div>
            <div className="col-lg-3 col-sm-6 services_section_secure__item">
              <img
                src={require("../assets/images/ico_services_secure_2.svg")}
                alt=""
              />
              <h4>Wykrywanie uszkodzonych linków</h4>
              <p>
                Wykrywamy i eliminujemy wszystkie uszkodzone linki prowadzące do
                Twojej strony.
              </p>
            </div>
            <div className="col-lg-3 col-sm-6 services_section_secure__item">
              <img
                src={require("../assets/images/ico_services_secure_3.svg")}
                alt=""
              />
              <h4>Analiza Google Page Speed 4x miesięcznie</h4>
              <p>Regularnie sprawdzamy szybkość działania Twojej strony.</p>
            </div>
            <div className="col-lg-3 col-sm-6 services_section_secure__item">
              <img
                src={require("../assets/images/ico_services_secure_4.svg")}
                alt=""
              />
              <h4>Optymalizacja prędkości działania serwisu</h4>
              <p>
                Optymalizujemy szybkość działania Twojej strony do najwyższej
                prędkości dostępnej dla technologii w oparciu o którą działa.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid services_section_optimisation">
          <h3>Serwisowanie i optymalizacja</h3>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <h4>1. Audyt bezpieczeństwa CMS (jednorazowo)</h4>
              <p>
                Na początek naszej współpracy przeprowadzamy audyt
                bezpieczeństwa Twojej strony i przesyłamy Ci jego rezultat wraz
                z naszymi rekomendacjami, tak aby strona była w pełni bezpieczna
                i zoptymalizowana.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>2. Pełny backup strony (pliki i baza)</h4>
              <p>
                Tworzymy pełne kopie zapasowe bazy danych i plików Twojej
                strony, tak aby w razie jakichkolwiek problemów móc w każdej
                chwili łatwo przywrócić ją do sprawnego działania.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>3. Monitoring działania strony - Uptime monitoring</h4>
              <p>
                Na bieżąco monitorujemy czy Twoja strona działa - jeżeli
                przestaje, jesteśmy o tym informowani w bardzo krótkim czasie i
                staramy się jak najszybciej przeanalizować i naprawić problem.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>4. Skaner antywirusowy</h4>
              <p>
                Na bieżąco skanujemy Twoją stronę w celu potencjalnego wykrycia
                złośliwego oprogramowania.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>5. Google Safe Browsing co 24 godziny</h4>
              <p>
                Monitorujemy listę na bieżąco aktualizowanych niebezpiecznych
                stron i linków mając na uwadze bezpieczeństwo Twojej strony i
                sprawdzając, czy nie jest ona potencjalną ofiarą ataku ze strony
                cyberprzestępców.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4>6. Monitoring aktywności logów</h4>
              <p>
                Monitorujemy i analizujemy wszelkie zmiany jakie zachodzą w
                historii aktywności Twojej strony internetowej.
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid services_section_support">
          <h3>Pomoc techniczna</h3>
          <div className="row">
            <div className="col-lg-4 services_section_support__item">
              <img
                src={require("../assets/images/ico_services_support_1.svg")}
                alt=""
              />
              <h4>Pomoc z użyciem pulpitu zdalnego</h4>
            </div>
            <div className="col-lg-4 services_section_support__item">
              <img
                src={require("../assets/images/ico_services_support_2.svg")}
                alt=""
              />
              <h4>Obsługa zgłoszeń serwisowych</h4>
              <p>Odpowiadamy maksymalnie do 48 godzin</p>
            </div>
            <div className="col-lg-4 services_section_support__item">
              <img
                src={require("../assets/images/ico_services_support_3.svg")}
                alt=""
              />
              <h4>Pomoc telefoniczna i e-mail w godzinach pracy biura</h4>
              <p>
                Porady techniczne dotyczące strony lub usługi od naszych
                ekspertów
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default ServicesPage
